import { WALKME_USER_ROLES } from '~/constants/userTypes';
import { ILogger, IWalkmeUserData } from '~/types';

import sentryGraphqlRequestError from '../SentryErrors/sentryGraphqlRequestError';

export const WalkMeInsightsTrack = (event: string, logger: ILogger): void => {
  if (event !== '') {
    try {
      window.WalkMeInsightsAPI.track(event); // Track a new page view
    } catch (err: unknown) {
      sentryGraphqlRequestError({ error: err, level: 'error', variables: {} });
      logger.warn('WalkMeInsightsTrack: Invalid event name', event);
    }
  }
};

export const WalkMeSetUserData = (userData: IWalkmeUserData): void => {
  /*
   * You may be wondering why I put in the localStorage and in the cookies right?
   * The answer is complex, but the only way I made it work was using them combined
   * Inserting only in the localStorage or only in the cookies didn't work
   *
   * Also there's another approach that would be to insert them as Variables in walkme
   * (see https://support.walkme.com/knowledge-base/insights-improving-your-session-filters-using-analytics-properties/)
   * But it didn't work as well. Why? Who knows....
   */
  if (userData) {
    const role = userData.role ? WALKME_USER_ROLES[userData.role] : 'Unknown';
    global.window.walkme_variables = { user_role: role, user_uid: userData.uid };
  }
};
