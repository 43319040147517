import { AlertProps } from '@mui/material/Alert';
import { forwardRef, SyntheticEvent } from 'react';

import TAG_IDS from '~/constants/tagIds';
import getIcon from '~/helpers/getIcon';

import IconButton from '../IconButton';

import { AlertStyled } from './styles';

export type SeverityType = 'error' | 'info' | 'success' | 'warning';

export type VariantType = 'standard' | 'filled' | 'outlined' | undefined;

export type IAlertProps = AlertProps & {
  message: string | React.ReactNode;
  onClose?: (event: SyntheticEvent<Element, Event>) => void;
  severity: SeverityType;
  title?: string;
  variant?: VariantType;
  width?: 'contentSize' | 'preset';
};

const AlertCircleOutline = getIcon('general.alert.circleOutline');
const InformationOutline = getIcon('general.alert.informationOutline');
const FlagOutline = getIcon('general.alert.flagOutline');
const CheckCircleOutlined = getIcon('general.checkmark.circle.outlined');
const AlertOutline = getIcon('general.alert.outline');
const Close = getIcon('general.close');

const ICON_BY_SEVERITY = {
  error: <AlertCircleOutline fontSize="large" />,
  info: <InformationOutline fontSize="large" />,
  standard: <FlagOutline fontSize="large" />,
  success: <CheckCircleOutlined fontSize="large" />,
  warning: <AlertOutline fontSize="large" />,
};

export const Alert = forwardRef<HTMLDivElement, IAlertProps>(
  ({ message, onClose, severity, title, variant = 'outlined', width = 'preset', ...props }: IAlertProps, ref): React.ReactElement<IAlertProps> => {
    const isStandardVariant = severity === 'info' && variant === 'standard';

    const renderCloseButton = (): React.ReactElement => {
      return (
        <IconButton disableRipple id={TAG_IDS.COMPONENTS.ALERT.ALERT_CLOSE_ICON} onClick={onClose} padding="medium" size="small">
          <Close />
        </IconButton>
      );
    };

    return (
      <AlertStyled
        data-testid="alert-component"
        icon={ICON_BY_SEVERITY[isStandardVariant ? 'standard' : severity]}
        id="alert-component"
        onClose={onClose}
        ref={ref}
        severity={severity}
        slots={{
          closeButton: () => renderCloseButton(),
        }}
        sx={{
          ...props.sx,
          minWidth: width === 'contentSize' ? 'unset' : undefined,
        }}
        title={title}
        variant={variant}
        {...props}
      >
        {message}
      </AlertStyled>
    );
  },
);

Alert.displayName = 'Alert';

export default Alert;
