import { Alert } from '@actinc/dls/components/Alert';

import { styled } from '~/helpers/styled';
import palette from '~/styles/ThemeEncourageE4E/palette';

// eslint-disable-next-line import/prefer-default-export
export const AlertStyled = styled(Alert)(() => ({
  '&.MuiAlert-standardInfo': {
    '.MuiAlert-icon': {
      display: 'none',
    },
  },
  '.MuiAlert-action': {
    button: {
      padding: 0,
    },
    marginLeft: '24px',
    marginRight: '0px',
    padding: 0,
    svg: {
      fill: `${palette.branding.sapphire[500]} !important`,
      height: '16px',
      width: '16px',
    },
  },
  '.MuiAlert-icon': {
    '& > svg': {
      height: '22px',
      width: '22px',
    },
    marginRight: '8px',
    padding: 0,
  },
  '.MuiAlert-message': {
    flexGrow: 1,
    padding: 0,
  },
  alignItems: 'center',
  borderRadius: '4px',
  display: 'flex',
  padding: '8px 16px',
}));
