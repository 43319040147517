const TAG_IDS = {
  ACCOUNT_SETUP: {
    EMAIL_READONLY_INPUT: 'account-setup-email-readonly-input',
    FIRST_NAME_INPUT: 'account-setup-first-name-input',
    LAST_NAME_INPUT: 'account-setup-last-name-input',
    LOGIN_LINK: 'account-setup-login-link',
    PASSWORD_CONFIRMATION_INPUT: 'account-setup-password-confirmaxtion-input',
    PASSWORD_INPUT: 'account-setup-password-input',
    PRIVACY_POLICY_AGREEMENT: 'account-setup-privacy-policy-agreement',
    PRIVACY_POLICY_LINK: 'account-setup-privacy-policy-link',
    SUBMIT_EDUCATOR_BUTTON: 'account-setup-submit-button',
    TERMS_OF_USE_AGREEMENT: 'account-setup-terms-of-use-agreement',
    TERMS_OF_USE_LINK: 'account-setup-terms-of-use-link',
    US_RESIDENT: 'account-setup-US-resident',
  },
  ACTIVATION: {
    EDUCATOR_ACTIVATION: {
      RESEND_CODE_BUTTON: 'resend-code-button',
      SEND_CODE_BUTTON: 'send-code-button',
      SUPPORT_REQUEST_FORM: {
        SUBMIT_SUPPORT_REQUEST_BUTTON: 'submit-support-request-button',
      },
    },
  },
  ADMINISTRATION: {
    ADD_USER_DIALOG: {
      ACCESS_TO_APP_MANAGEMENT_INPUT: 'access-to-app-management-input',
      ACCESS_TO_TRANSCRIPT_SUBMISSIONS_CHECKBOX: 'access-to-transcript-submissions-checkbox',
      ACCESS_TO_TRANSCRIPT_SUBMISSIONS_INPUT: 'access-to-transcript-submissions-input',
      APP_MANAGEMENT_PERMISSION_SWITCH: 'add-user-dialog-app-management-switch',
      CANCEL_BUTTON: 'add-new-user-dialog-cancel-button',
      EMAIL_INPUT: 'email-input',
      EMAIL_TEXTFIELD: 'email-input',
      FIRST_NAME_INPUT: 'first-name-input',
      FIRST_NAME_TEXTFIELD: 'first-name-textfield',
      IS_USER_ADMINISTRATOR_INPUT: 'is-user-administrator-input',
      IS_USER_ADMINISTRATOR_SWITCH: 'is-user-administrator-switch',
      LAST_NAME_INPUT: 'last-name-input',
      PARTNER_INPUT: 'partner-input',
      PREFIX_INPUT: 'prefix-input',
      ROLE_INPUT: 'role-input',
      SCHOOL_DROPDOWN: 'school-dropdown',
    },
    ADMINISTRATION_TABS: 'administration_tabs',
    ADMINISTRATOR_CHECKBOX: 'administration_tabs',
    DELETE_USER_DIALOG: {
      CANCEL_BUTTON: 'administration-delete-user-dialog-cancel-button',
      CLOSE_BUTTON: 'administration-delete-user-dialog-close-button',
      CONFIRM_BUTTON: 'administration-delete-user-dialog-confirm-button',
    },
    DELETE_USER_MENU_ITEM: 'administration-delete-user-menu-item',
    EDIT_USER_DIALOG: {
      ACCESS_TO_APP_MANAGEMENT_INPUT: 'edit-access-to-app-management-input',
      ACCESS_TO_TRANSCRIPT_SUBMISSIONS_INPUT: 'edit-access-to-transcript-submissions-input',
      ADMINISTRATION_TABS: 'edit-administration-tabs',
      APP_MANAGEMENT_PERMISSION_SWITCH: 'application-management-switch',
      CHANGE_PASSWORD: 'access-to-change-password',
      CHANGE_PASSWORD_INPUT: 'change-password-input',
      CLOSE_ICON_BUTTON: 'dialog-close-icon-button',
      CONFIRM_PASSWORD_INPUT: 'confirm-password-input',
      CREDENTIAL_SUBMISSION_PERMISSION_CHECKBOX: 'credential-submission-permission',
      EDIT_EMAIL_TEXTFIELD: 'edit-email-textfield',
      EDIT_FIRST_NAME_TEXTFIELD: 'edit-first-name-textfield',
      EDIT_LAST_NAME_TEXTFIELD: 'edit-last-name-textfield',
      HS_ADMIN_PERMISSION_SWITCH: 'highschool-admin-permission',
      IMPERSONATE: 'access-to-impersonate',
      IS_USER_ADMINISTRATOR_INPUT: 'edit-is-user-administrator-input',
      ROLE_INPUT: 'edit-role-input',
      SAVE_USER: 'save-user',
      SCHOOL_SELECT_DROPDOWN: 'edit-user-school-select-dropdown',
    },
    EDIT_USER_MENU_ITEM: 'administration-edit-user-menu-item',
    RESEND_INVITATION_DIALOG: {
      CANCEL_BUTTON: 'administration-resend-invitation-dialog-cancel-button',
      CLOSE_BUTTON: 'administration-resend-invitation-dialog-close-button',
      CONFIRM_BUTTON: 'administration-resend-invitation-dialog-confirm-button',
    },
    RESEND_INVITATION_MENU_ITEM: 'administration-resend-invitation-menu-item',
    USER_LIST_ACTIONS_BUTTON: 'administration-user-list-actions-button',
  },
  APP_LAYOUT: {
    CLOSE_DRAWER_ICON_BUTTON: 'close-drawer-app-layout-icon-button',
    SEARCH_FOR_SCHOOLS_BUTTON: 'search-for-schools-button',
    USER_MENU_ACTION_ITEM: 'user-menu-action-item',
  },
  COMPONENTS: {
    ALERT: {
      ALERT_CLOSE_ICON: 'alert-close-icon',
    },
    CHECKLIST_YEAR: {
      YEAR_SELECTION: (year: string): string => `checklist-year-selection-${year}-menu-item`,
    },
    CODEINPUT: {
      CLEAN_CODE_BUTTON: 'clean-code-input-button',
    },
    HORIZONTAL_CARD: {
      BUTTON: 'horizontal-card-button',
    },
    MENU_LIST: {
      MENU_LIST_ITEM: (action: string): string => `menu-list-item-index-${action}`,
    },
    SELECT_DROPDOWN: {
      MENU_ITEM: 'select-dropdown-menu-item',
      NONE_MENU_ITEM: 'select-dropdown-none-menu-item',
      PLACEHOLDER_MENU_ITEM: 'select-dropdown-placeholder-menu-item',
    },
    STUDENT_SEARCH_BAR: {
      LINK: 'student-search-bar-component-link',
    },
  },
  CONFIRM: {
    CANCEL_BUTTON: (name: string): string => `dialog-${name}-cancel-button`,
    CONFIRM_BUTTON: (name: string): string => `dialog-${name}-confirm-button`,
    DIALOG_ID: (name: string): string => `dialog-${name}`,
  },
  DEMO_STYLES: {
    DEMO_ACCORDION: 'demo-styles-page-accordion',
    DEMO_MENU: 'demo-styles-page-menu',
    DEMO_MENU_ITEM: 'demo-styles-page-menu-item',
    DEMO_NO_RESULTS_BUTTON: 'demo-styles-page-no-results-button',
    DEMO_SNACKBAR_SWITCH: 'demo-styles-page-snackbar-switch',
    DEMO_SWITCH: 'demo-styles-page-switch',
    DEMO_SWITCH_LABEL_LEFT: 'demo-styles-page-switch-label-left',
    DEMO_SWITCH_LABEL_RIGHT: 'demo-styles-page-switch-label-right',
    DEMO_TAB: 'demo-styles-page-tab',
    DEMO_TABLE_CONTENT_LINK: 'demo-styles-page-table-content-link',
    DEMO_TOOLTIP_ICON_BUTTON: 'demo-style-page-tooltip-icon-button',
  },
  DIALOGS: {
    ALERT_DIALOG: {
      DIALOG_ID: (name: string): string => `alert-dialog-${name}`,
      OK_BUTTON: (name: string): string => `alert-dialog-${name}-button`,
    },
    COLLEGES_LIST: {
      CONFIRM_BUTTON: 'dialog-college-list-confirm-button',
      CONTENT: 'dialog-college-list-content',
    },
    CREDENTIALS_SUBMISSION: {
      DELETE_FILE_BUTTON: 'dialog-credentials-submission-delete-file',
      DIALOG_CANCEL: 'dialog-credentials-submission-cancel',
      DIALOG_SUBMIT: 'dialog-credentials-submission-submit',
      FERPA_CHECKBOX: 'dialog-credentials-submission-ferpa-checkbox',
      OPEN_CREDENTIALS_MENU: 'dialog-credentials-type-menu',
      OPEN_FILE_BUTTON: 'dialog-credentials-submission-open-file',
      SELECT_INPUT: 'dialog-credentials-submission-select',
      UPLOAD_BUTTON: 'dialog-credentials-upload-button',
      UPLOAD_FILE: 'dialog-credentials-upload-file-reader',
      UPLOAD_FILE_ICON_BUTTON: 'dialog-credentials-upload-file-reader-icon-button',
    },
    DELETE_DOCUMENT: {
      CANCEL_BUTTON: 'dialog-delete-document-cancel-btn',
      DELETE_BUTTON: 'dialog-delete-document-delete-btn',
      TEXT_FIELD: 'dialog-delete-document-reason-text-field',
    },
    EDUCATION_PARTNER: {
      CLOSE_BUTTON: 'dialog-education-partner-modal-close-button',
      CONTENT: 'dialog-education-partner-modal-content',
      GO_TO_REPORT_BUTTON_PREFIX: 'dialog-education-partner-button-go-to-',
      MODAL: 'dialog-education-partner-home-modal',
      REPORT_TITLE_BUTTON_PREFIX: 'dialog-education-partner-title-button-',
    },
    HOME: {
      CONTENT: 'dialog-home-modal-content',
      DONT_SHOW_ME_THIS_CHECKBOX: 'dialog-dont-show-me-this-next-time',
      GET_STARTED_BUTTON: 'dialog-home-modal-get-started',
      ROOT_DEFAULT: 'dialog-home-modal',
      SELECT_SCHOOL_BUTTON: 'dialog-home-modal-select-school',
    },
    IMPERSONATE: {
      CANCEL_BUTTON: 'dialog-impersonate-cancel-button',
      FIND_BY_EMAIL_INPUT: 'dialog-impersonate-find-by-email-input',
      FIND_BY_EMAIL_TEXTFIELD: 'dialog-impersonate-find-by-email-textfield',
      FIND_BY_NAME_INPUT: 'dialog-impersonate-find-by-name-input',
      FIND_BY_NAME_TEXTFIELD: 'dialog-impersonate-find-by-name-textfield',
    },
    INVITE_STUDENTS: {
      CLOSE_BUTTON: 'dialog-invite-students-close-button',
      COPY_ALL_SECTIONS_BUTTON: 'dialog-invite-copy-all-button',
      COPY_ALL_SECTIONS_GRID: 'dialog-invite-students-copy-grid',
      COPY_EDUCATOR_CODE_GRID: 'dialog-invite-copy-code-grid',
      COPY_EDUCATOR_CODE_SECTION_BUTTON: 'dialog-invite-copy-code-grid-button',
      COPY_EDUCATOR_CODE_SECTION_BUTTON_WRAPPER: 'dialog-invite-copy-code-grid-button-wrapper',
      COPY_QR_CODE_GRID: 'dialog-invite-copy-qr-grid',
      COPY_QR_CODE_SECTION_BUTTON: 'dialog-invite-copy-qr-grid-button',
      COPY_QR_CODE_SECTION_BUTTON_WRAPPER: 'dialog-invite-copy-qr-grid-button-wrapper',
      COPY_QR_CODE_TEXT_WRAPPER: 'dialog-invite-copy-qr-text-wrapper',
      COPY_SECTIONS_PARENT_WRAPPER_GRID: 'dialog-invite-copy-sections-parent-wrapper-grid',
      COPY_SECTIONS_WRAPPER_GRID: 'dialog-invite-copy-sections-wrapper-grid',
      EDUCATOR_LINK: 'dialog-invite-students-educator-link',
      FULL_SCREEN_BUTTON: 'dialog-invite-students-fullscreen-button',
      INSTRUCTIONS_BUTTON: 'dialog-invite-students-instructions-button',
      INSTRUCTIONS_GRID: 'dialog-invite-students-instructions-grid',
      QR_CODE_IMAGE: 'dialog-invite-students-qr-code-img',
      QR_CODE_NEWLINE: 'dialog-invite-students-qr-code-typography',
    },
    MATCH_REPORT_DOWNLOAD_DIALOG: {
      ACTIONS: 'dialog-match-report-actions',
      CANCEL_BUTTON: 'dialog-match-report-cancel-btn',
      CONFIRM_BUTTON: 'dialog-match-report-confirm-btn',
      CONTENT: 'dialog-match-report-content',
      GENERATE_BUTTON: 'dialog-match-report-generate-btn',
      PARTIAL_ERROR_MESSAGE: 'dialog-match-report-partial-error-msg',
      TRYAGAIN_BUTTON: 'dialog-match-report-tryagain-btn',
    },
    MESSAGE: {
      CLOSE_BUTTON: 'dialog-message-close-button',
    },
    SCHOLARSHIPS_LIST: {
      CONFIRM_BUTTON: 'dialog-scholarships-list-confirm-button',
      CONTENT: 'dialog-scholarships-list-content',
    },
    STUDENT_INVITATION: {
      CLOSE_BUTTON: 'dialog-student-invitation-cta-close-button',
      CONTENT: 'dialog-student-invitation-cta-content',
      INVITE_STUDENTS_BUTTON: 'dialog-student-invitation-cta-invite-students-button',
      ROOT_DEFAULT: 'dialog-student-invitation-cta',
    },
    TRANSCRIPT_SUBMISSION_DATE: {
      CANCEL_BUTTON: 'dialog-transcript-submission-cancel-button',
      DATE_INPUT: 'dialog-transcript-submission-date-input',
      SUBMIT_BUTTON: 'dialog-transcript-submission-submit-button',
    },
  },
  FORGOT_PASSWORD: {
    FORGOT_CONTACT_US_LINK: 'forgot-contact-us-link',
    FORGOT_EMAIL: 'forgot-email-input',
    FORGOT_EMAIL_TEXTFIELD: 'forgot-email-textfield',
    FORGOT_LOG_IN_BUTTON: 'forgot-log-in-button',
    FORGOT_LOG_IN_LINK: 'forgot-log-in-link',
    FORGOT_SUBMIT_BUTTON: 'forgot-submit-button',
  },
  INVITATION: {
    EMAIL_TEXTFIELD: 'invitation-email-textfield',
    LOG_IN_LINK: 'invitation-log-in-link',
    SUBMIT_BUTTON: 'invitation-submit-button',
    TERMS_OF_USE_LINK: 'invitation-terms-of-use-link',
  },
  LEFT_NAV: {
    APPLICATION_MANAGEMENT_ACTION: 'application-management-action',
    LESSON_PLANS_ACTION: 'lesson-plans-action',
    MY_STUDENTS_ACTION: 'my-students-action',
    REPORTS_ACTE_REPORT_ACTION: 'reports-acte-report-action',
    REPORTS_ACTFL_REPORT_ACTION: 'reports-actfl-report-action',
    REPORTS_ART_REPORT_ACTION: 'reports-art-report-action',
    REPORTS_COF_JROTC_REPORT_ACTION: 'reports-cof-jrotc-report-action',
    REPORTS_DECA_REPORT_ACTION: 'reports-deca-report-action',
    REPORTS_FBLA_REPORT_ACTION: 'reports-fbla-report-action',
    REPORTS_FCCLA_REPORT_ACTION: 'reports-fccla-report-action',
    REPORTS_MAA_REPORT_ACTION: 'reports-maa-report-action',
    REPORTS_MCS_REPORT_ACTION: 'reports-mcs-report-action',
    REPORTS_MO_REPORT_ACTION: 'reports-mo-report-action',
    REPORTS_NABSE_REPORT_ACTION: 'reports-nabse-report-action',
    REPORTS_NAFME_REPORT_ACTION: 'reports-nafme-report-action',
    REPORTS_NATIONAL_REPORT_ACTION: 'reports-national-report-action',
    REPORTS_NCSS_REPORT_ACTION: 'reports-ncss-report-action',
    REPORTS_SHAPE_AMERICA_REPORT_ACTION: 'reports-shape-america-report-action',
    REPORTS_STEM_CONNECTOR_REPORT_ACTION: 'reports-stem-connector-report-action',
    REPORTS_SUBMENU_ACTION: 'reports-submenu-action',
    REPORTS_SUPERINTENDENT_DISTRICT_POSTSECONDARY_REPORT: 'reports-district-postsecondary-report-action',
    REPORTS_SUPERINTENDENT_REPORT_ACTION: 'reports-superintendent-report-action',
    REPORTS_USERS_REPORT_ACTION: 'reports-users-report-action',
    RESEARCH_SUBMENU_ACTION: 'research-submenu-action',
  },
  LIST: {
    BOX: (name: string): string => `list-box-${name}`,
    ITEM_ID: (id: string, index: number): string => `${id}-item${index}`,
  },
  LOGIN: {
    FORGOT_PASSWORD_LINK: 'forgot-password-link',
    LOGIN_BUTTON: 'login-button',
    LOGIN_EMAIL_INPUT: 'email-input',
    LOGIN_EMAIL_TEXTFIELD: 'log-in-email-textfield',
    LOGIN_ERROR_MESSAGE_ALERT: 'login-error-message-alert',
    LOGIN_PASSWORD_INPUT: 'password-input',
    REQUEST_ASSISTANCE_LINK: 'request-assistance-link',
  },
  MY_STUDENTS: {
    FILTER_SELECTION: {
      GRAD_YEAR_CHECKBOX: 'student-list-grad-year-checkbox',
      GRAD_YEAR_MENU_ITEM: 'student-list-grad-year-menu-item',
    },
    INVITE_STUDENTS: {
      COPY_CODE_BUTTON: 'copy-code-button',
      INVITE_STUDENTS_BUTTON: 'invite-students-button',
    },
    LIST: {
      CHIP_DELETE_BUTTON_DATA_SOURCES_ICON: 'chip-delete-button-data-sources-icon',
      CHIP_DELETE_BUTTON_GRAD_YEARS_ICON: 'chip-delete-button-grad-years-icon',
      CHIP_DELETE_BUTTON_SURV_DATE_ICON: 'chip-delete-button-survey-date-icon',
      CLEAR_FILTER_BUTTON: `my-students-clear-filter-button`,
      COLLEGE_MATCH_PDF_BUTTON: 'college-match-pdf-button',
      DATA_SOURCE_FILTER_SELECT: `my-students-data-source-select-component`,
      END_SURVEY_DATE_FILTER_ICON_BUTTON: 'end-survey-date-filter-icon-button',
      END_SURVEY_DATE_FILTER_INPUT: 'end-survey-date-filter-input',
      FILTER_BUTTON: `my-students-filter-button`,
      GRAD_YEARS_FILTER_SELECT: `my-students-grad-years-select-component`,
      REPORT_DATE_RANGE_POPUP: 'report-date-range-popup',
      SELECT_ALL_STUDENTS: `my-students-select-all-students-checkbox`,
      SELECT_DATE_SURVEY_BUTTON: 'my-students-select-date-button',
      SELECT_DATE_SURVEY_INPUT: 'my-students-select-date-input',
      SELECT_STUDENT: (id: string): string => `my-students-select-student-checkbox-${id}`,
      SELECT_SURVEY_DATE_FILTER_BUTTON: 'select-survey-date-filter-button',
      START_SURVEY_DATE_FILTER_ICON_BUTTON: 'start-survey-date-filter-icon-button',
      START_SURVEY_DATE_FILTER_INPUT: 'start-survey-date-filter-input',
      STUDENT_SEARCH_INPUT: 'my-student-search-input',
      STUDENT_TABLE_MENU_ICON_PREFIX: 'my-student-menu-icon',
      STUDENT_TABLE_SORT_PREFIX: 'my-student-table-sort',
      SURVEY_DATE_CLEAR_BUTTON: 'survey-date-clear-button',
      SURVEY_DATE_CLEAR_ICON_BUTTON: 'survey-date-clear-icon-button',
      YEAR_DROPDOWN: (id: string): string => `my-students-list-year-${id}`,
    },
    STUDENT_DETAILS: {
      BACK_TO_MY_STUDENTS_LIST_BUTTON: 'back-to-my-students-list-button',
      BACK_TO_MY_STUDENTS_LIST_BUTTON_LINK: 'back-to-my-students-list-button-link',
      COLLEGE_LIST_WIDGET: {
        CONTAINER: 'college-list-container',
        TAB_SELECTOR: 'college-list',
        TITLE: 'student-details-college-list-title',
      },
      COLLEGE_PREFERENCES_WIDGET: {
        CONTAINER: 'student-details-college-preferences-container',
      },
      COLLEGES: {
        MATCHED_COLLEGES: (collegeIndex: number): string => `matched-colleges-${collegeIndex}`,
        SAVED_COLLEGES: (collegeIndex: number): string => `saved-colleges-${collegeIndex}`,
        TEST_MATCHED_COLLEGES: (collegeIndex: number): string => `test-matched-colleges-${collegeIndex}`,
        TEST_SAVED_COLLEGES: (collegeIndex: number): string => `test-saved-colleges-${collegeIndex}`,
      },
      COLLEGES_LIST_MATCHED: 'college-list-matched',
      COLLEGES_LIST_SAVED: 'college-list-saved',
      COLLEGES_SCHOLARSHIPS_WIDGET: {
        CONTAINER: 'student-details-college-scholarships-container',
        SECTION_INFO_DIALOG: 'student-details-college-scholarships-section-info-dialog',
      },
      COPY_E4S_DOWNLOAD_LINK_BUTTON: 'my-student-details-copy-e4s-download-link-button',
      DETAILS_BUTTON_LINK: 'student-details-button-link',
      E4S_DOWNLOAD_LINK_FIELD: 'e4s-download-link-field',
      E4S_DOWNLOAD_LINK_TEXTFIELD: 'e4s-download-link-textfield',
      ENCOURAGE_ME_LINK: 'encourage-me-link',
      ENCOURAGE_SUPPORT_LINK: 'encourage-support-link',
      GOALS_WIDGET: {
        CONTAINER: 'student-details-goals-container',
        VIEW_ALL_BUTTON: 'student-details-goals-view-all-button',
      },
      INFORMATION_WIDGET: {
        CONTAINER: 'student-details-information-container',
      },
      MAJOR_AND_CAREERS: {
        ACADEMIC_EXPLORER_CONTAINER: 'student-details-major-and-careers-academic-explorer-container',
        ACADEMIC_EXPLORER_PANEL: (panelTitle: string): string => `academic-explorer-panel-${panelTitle}`,
        CAREER_DETAILS_DIALOG: {
          CONFIRM_BUTTON: 'student-details-major-and-careers-career-details-dialog-confirm-button',
          CONTENT: 'student-details-major-and-careers-career-details-dialog-content',
        },
        CAREERS_WIDGET: 'student-details-major-and-careers-careers-widget',
        CONTAINER_WIDGET: 'student-details-major-and-careers-container-widget',
        MAJOR_ITEMS_DIALOG_ITEM: 'student-details-major-items-list-item',
        MAJOR_ITEMS_DIALOG_LIST: 'student-details-major-items-list-dialog',
        MAJOR_SAVED_LIST: 'student-details-majors-saved-list',
        MAJOR_WIDGET: 'student-details-major-widget-container',
        SECTION_INFO_DIALOG: 'student-details-major-and-careers-section-info-dialog',
        SECTION_INFO_DIALOG_CLOSE_BUTTON: 'student-details-major-and-careers-section-info-dialog-close-button',
      },
      OPEN_DIALOG_GET_E4S_BUTTON: 'open-dialog-get-e4s-button',
      PROSPECTIVE_COLLEGES_WIDGET: {
        TAB_SELECTOR: 'prospective-colleges',
        TITLE: 'mentoring-details-prospective-colleges-title',
      },
      SCHOLARSHIPS_COLLEGE_CELL: (index: number): string => `scholarship-college-cell-${String(index)}`,
      SCHOLARSHIPS_OPPORTUNITIES: {
        INVITE_STUDENT_TO_CONNECT_BUTTON: 'student-details-scholarship-opportunities-invite-student-button',
        INVITE_STUDENTS_DIALOG: {
          COPY_LINK_BUTTON: 'student-details-colleges-and-scholarships-invite-students-copy-link-button',
          LINK_TEXTFIELD: 'student-details-colleges-and-scholarships-invite-students-link-textfield',
        },
        LIST_MATCHED: 'scholarships-list-matched',
        LIST_SAVED: 'scholarships-list-saved',
        LIST_WIDGET: {
          CONTAINER: 'scholarships-list-container',
          TAB_SELECTOR: 'scholarships-list',
          TITLE: 'student-details-scholarships-list-title',
        },
      },
      SECTION_INFO_DIALOG: {
        CLOSE_BUTTON: (parentId: string): string => `${parentId}-close-button`,
      },
      VIEW_ALL_BUTTON: (sectionTitle: string): string => `student-details-${sectionTitle}-view-all-button`,
      WIDGET_COMPONENT: {
        INFORMATION_ICON: '-widget-information-icon',
      },
    },
    TABLE: {
      DETAILS_LINK: (index: number): string => `my-students-table-details-link-index-${String(index)}`,
      DETAILS_LINK_BUTTON: (index: number): string => `my-students-table-details-link-index-button-${String(index)}`,
    },
  },
  NOT_FOUND_PAGE: {
    NOT_FOUND_HOME_LINK: 'not-found-page-home-link',
    NOT_FOUND_HOME_LINK_BUTTON: 'not-found-page-home-link-button',
  },
  OPTOUT: {
    ACTION_BUTTON_RESUBSCRIBE: 'optout-action-resubscribe',
    ACTION_BUTTON_UNSUBSCRIBE: 'optout-action-unsubscribe',
    CANCEL_BUTTON_RESUBSCRIBE: 'optout-cancel-resubscribe',
    CANCEL_BUTTON_UNSUBSCRIBE: 'optout-cancel-unsubscribe',
  },
  PAGE_HEADER: {
    GO_BACK_BUTTON: 'page-header-go-back-button',
    GO_BACK_BUTTON_LINK: 'page-header-go-back-button-link',
    SECTION_TAB: 'page-header-section-tab',
  },
  PRIME_TABLE: {
    SELECT_FIRST_PAGE_BUTTON: 'prime-table-select-first-page-button',
    SELECT_LAST_PAGE_BUTTON: 'prime-table-select-last-page-button',
    SELECT_NEXT_PAGE_BUTTON: 'prime-table-select-next-page-button',
    SELECT_PAGINATION: 'prime-table-select-pagination',
    SELECT_PAGINATION_ITEM: (index: number): string => `prime-table-select-pagination-page-${index}`,
    SELECT_PREVIOUS_PAGE_BUTTON: 'prime-table-select-previous-page-button',
  },
  PROFILE_MANAGEMENT: {
    ACTION_BUTTONS: {
      CANCEL_BUTTON: 'cancel-button',
      EDIT_BUTTON: (sectionId: string): string => `${sectionId}-edit-button`,
    },
    EDIT_PASSWORD_DIALOG: {
      CANCEL_BUTTON: 'cancel-button',
      CONFIRM_PASSWORD_INPUT: 'confirm-password-input',
      CURRENT_PASSWORD_INPUT: 'current-password-input',
      PASSWORD_INPUT: 'edit-password-input',
      SAVE_PASSWORD: 'edit-password-save-button',
    },
    EDIT_PROFILE: {
      CANCEL_BUTTON: 'cancel-button',
      EMAIL_INPUT: 'email-input',
      EMAIL_TEXTFIELD: 'profile-email-textfield',
      FIRST_NAME_INPUT: 'first-name-input',
      LAST_NAME_INPUT: 'last-name-input',
      PHONE_INPUT: 'phone-input',
      PHONE_TEXTFIELD: 'phone-textfield',
      PROFILE_FIRST_NAME_TEXTFIELD: 'profile-first-name-textfield',
      PROFILE_LAST_NAME_TEXTFIELD: 'profile-last-name-textfield',
      SAVE_BUTTON: 'save-button',
    },
    POLICIES: {
      POLICY_BUTTON: 'policy-button',
      POLICY_BUTTON_ICON: 'policy-button-icon',
      POLICY_LINK: 'policy-link',
      PRIVACY_POLICY_URL_LINK: 'privacy-policy-url-link',
      TERMS_OF_USE_URL_LINK: 'policy-terms-of-use-url-link',
    },
  },
  REPORTS: {
    DOWNLOAD_USERS_REPORT_BUTTON: 'download-users-report-button',
    EXPORT_REPORTS_BUTTON: 'export-report-button',
    EXPORT_REPORTS_FBA: 'export-report-fba-button',
  },
  REQUEST_ACCESS: {
    EMAIL_INPUT: 'email-input',
    FIRST_NAME_INPUT: 'first-name-input',
    JOB_TITLE_INPUT: 'job-title-input',
    LAST_NAME_INPUT: 'last-name-input',
    REQUEST_LOGIN_BUTTON: 'request-login-button',
    RESET_SCHOOL_FIELDS_BUTTON: 'reset-school-fields-button',
    SCHOOL_CITY_INPUT: 'school-city-input',
    SCHOOL_INPUT: 'school-input',
    SCHOOL_NAME_INPUT: 'school-name-input',
    SCHOOL_NAME_LABEL: 'school-name-label',
    SCHOOL_SEARCH_LIST: 'school-search-list',
    SCHOOL_STATE_INPUT: 'school-state-input',
    SCHOOL_ULR_INPUT: 'school-url-input',
  },
  RESET_PASSWORD: {
    CONFIRM_PASSWORD_TEXTFIELD: 'reset-password-confirm-textfield',
    EMAIL_TEXTFIELD: 'reset-password-email-textfield',
    LOG_IN_BUTTON: 'reset-password-log-in-button',
    LOG_IN_LINK: 'reset-password-log-in-link',
    PASSWORD_TEXTFIELD: 'reset-password-textfield',
    SAVE_BUTTON: 'reset-password-save-button',
    SUCCESS_BUTTON: 'reset-password-success-sign-in-link',
    SUCCESS_LINK: 'reset-password-success-sign-in-button',
  },
  SCHOOL_APPLICATION: {
    APPLICATION_MENU_ICON: 'school-application-general-menu-icon-button',
    APPLICATION_MENU_ITEM_LINK: 'school-application-menu-item-link',
    APPLICATION_MENU_ITEM_SUBMISSION: 'school-application-menu-item-submission',
    APPLICATION_MENU_URL_LINK: 'school-application-menu-external-link',
    APPLICATION_STUDENT_DETAILS_LINK: 'school-application-details-link',
    APPLICATION_URL_LINK: 'school-application-external-link',
  },
  SCHOOL_SEARCH: {
    SCHOOL_SEARCH_AUTOCOMPLETE_MENU_HEADER: 'school-search-autocomplete-menu-header',
    SCHOOL_SEARCH_AUTOCOMPLETE_TEXTFIELD: 'school-search-autocomplete-textfield',
    SEARCH_COMPONENT_CLEAR_BUTTON: 'search-component-clear-button',
    SEARCH_SCHOOL_AUTOCOMPLETE_OPTION: (hsId: string): string => `search-school-autocomplete-option-${hsId}`,
    SEARCH_SCHOOL_LINK: (hsId: string): string => `search-school-link-${hsId}`,
    SECONDARY_SCHOOL_AUTOCOMPLETE_INPUT: 'secondary-school-autocomplete-input',
  },
  TOP_BAR: {
    SCHOOL_DROPDOWN: 'school-dropdown-button',
  },
  USER_MENU: {
    ADMINISTRATION_ACTION: 'administration-action',
    END_IMPERSONATE_ACTION: 'end-impersonate-action',
    HELP_ACTION: 'help-action',
    IMPERSONATE_ACTION: 'impersonate-action',
    LOGOUT_ACTION: 'logout-action',
    MENU_DROPDOWN: 'user-menu-dropdown-button',
    PROFILE_MANAGEMENT_ACTION: 'user-menu-profile-management',
  },
};

export default TAG_IDS;
