import { AvatarProps } from '@mui/material/Avatar';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { IconSelector } from '~/helpers/getIcon';

import { StyledAvatar, StyledIcon } from './styles';

type IAvatarProps = AvatarProps & {
  colorVariant: 'navy' | 'seafoam' | 'neon' | 'white';
  icon?: typeof SvgIcon | IconSelector;
  isDisabled?: boolean;
  label?: string;
  size: 'small' | 'medium' | 'large' | 'xlarge';
  iconColor?: string;
};

const Avatar: FC<IAvatarProps> = ({ colorVariant, isDisabled = false, icon, iconColor, label, size, ...props }: IAvatarProps) => {
  if (!label && icon) {
    return (
      <StyledAvatar $colorVariant={colorVariant} $disabled={isDisabled ?? false} $size={size} {...props}>
        <StyledIcon $size={size} icon={icon} sx={{ fill: iconColor }} />
      </StyledAvatar>
    );
  }
  return (
    <StyledAvatar $colorVariant={colorVariant} $disabled={isDisabled} $size={size} {...props}>
      <Typography variant={size === 'xlarge' ? 'h6' : 'body1Strong'}>{label?.toUpperCase()}</Typography>
    </StyledAvatar>
  );
};

export default Avatar;
