import { Loading } from '@actinc/dls/components/Loading';
import { useApolloClient } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import moment from 'moment';
import { useRouter } from 'next/router';
import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';

import EducationPartnerDialogHandler from '~/components/EducationPartnerDialogHandler';
import HomeDialogHandler from '~/components/HomeDialogHandler';
import Icon from '~/components/Icon';
import Link from '~/components/Link';
import NotFound404 from '~/components/NotFound404';
import RefreshToken from '~/components/RefreshToken';
import SchoolSelectionDialog from '~/components/SchoolSelectionDialog';
import NAMES from '~/constants/names';
import { EDUCATION_PARTNER_HS_ID } from '~/constants/reports';
import { ROUTES } from '~/constants/routes';
import TAG_IDS from '~/constants/tagIds';
import USER_TYPES from '~/constants/userTypes';
import { asError } from '~/helpers/errors';
import sentryGraphqlRequestError from '~/helpers/SentryErrors/sentryGraphqlRequestError';
import useActiveUser from '~/hooks/useActiveUser';
import useDetailedPermissions from '~/hooks/useDetailedPermissions';
import useLogger from '~/hooks/useLogger';
import useSchoolId from '~/hooks/useSchoolId';
import useSchoolInfo from '~/hooks/useSchoolInfo';
import { clearTokens } from '~/hooks/useToken';

import {
  BarContentContainer,
  BarContentGridContainer,
  BarRoot,
  BarWrapper,
  Footer,
  HighschoolSelectButton,
  HighschoolSelectedText,
  LogoImg,
  PageContentContainer,
  PageMain,
} from './styles';
import UserMenu from './UserMenu';

interface IProps {
  children: ReactNode;
  contentContainerProps?: SxProps;
  forceLogout?: boolean;
  hasSecondaryHeader?: boolean;
  hideSearchSchools?: boolean;
  hideUserMenu?: boolean;
  is404?: boolean;
  isHeaderLogoClickable?: boolean;
  loading?: boolean;
  showFooter?: boolean;
}

const AppLayout: FC<IProps> = ({
  children,
  contentContainerProps,
  forceLogout = false,
  hasSecondaryHeader = false,
  hideSearchSchools = false,
  hideUserMenu = false,
  is404 = false,
  isHeaderLogoClickable = true,
  loading = false,
  showFooter = true,
}: IProps): ReactElement => {
  const [showSchoolSearch, setShowSchoolSearch] = useState(false);

  const logger = useLogger('APP_LAYOUT');
  const activeUser = useActiveUser();
  const client = useApolloClient();
  const router = useRouter();
  const schoolId = useSchoolId();
  const getDetailedPermissions = useDetailedPermissions();

  if (getDetailedPermissions.error) {
    logger.error(getDetailedPermissions.error);
  }

  const onExpire = async (): Promise<void> => {
    clearTokens();
    const nextPage = `${ROUTES.LOGIN}${router.asPath ? `?returnUrl=${router.asPath}` : ''}`;
    try {
      await router.push(nextPage);
      await client.clearStore();
    } catch (err: unknown) {
      sentryGraphqlRequestError({ error: err, level: 'error', variables: {} });
      logger.error(asError(err));
    }
  };

  const { data, loading: isLoadingSecondarySchool } = useSchoolInfo();
  const schoolName = data?.school?.name;

  const [userCanSearchSchools, setUserCanSearchSchools] = useState<boolean>();

  const detailedPermissions = getDetailedPermissions.data?.detailedPermissions;
  const isUserLoggedIn = Boolean(activeUser);
  const userHsIds = detailedPermissions?.map(permission => permission.organization?.externalId);

  useEffect(() => {
    if (forceLogout) {
      const handleLogout = async (): Promise<void> => {
        clearTokens();

        await client.resetStore();
      };

      // eslint-disable-next-line promise/prefer-await-to-then
      handleLogout().catch((unkErr: unknown) => {
        const error = asError(unkErr);
        logger.error('Error while trying to sign out', error);
      });
    }

    const isLdapUser = activeUser?.type === USER_TYPES.LDAP;
    const accessToMultiSchools = userHsIds && userHsIds?.length > 1;
    const accessToCurrentSchool = userHsIds && userHsIds?.length === 1 && userHsIds[0] === schoolId;

    setUserCanSearchSchools(isLdapUser || accessToMultiSchools || !accessToCurrentSchool);
  }, [userHsIds, schoolId, activeUser, logger, client, forceLogout]);

  const handleDialogClose = (): void => {
    setShowSchoolSearch(false);
  };

  const logoImgSrc = isUserLoggedIn ? '/static/img/logo.svg' : '/static/img/logo-color.png';

  return (
    <>
      <RefreshToken
        onExpire={(): void => {
          void onExpire(); // eslint-disable-line no-void
        }}
      />

      <BarWrapper>
        <BarRoot $loggedIn={isUserLoggedIn} elevation={hasSecondaryHeader ? 0 : 2}>
          <BarContentContainer maxWidth={false}>
            <BarContentGridContainer container>
              <Grid item>
                {isHeaderLogoClickable ? (
                  <Link href={ROUTES.INDEX} id={TAG_IDS.TOP_BAR.SCHOOL_DROPDOWN}>
                    <LogoImg alt={NAMES.APP} aria-label={NAMES.APP} src={logoImgSrc} />
                  </Link>
                ) : (
                  <LogoImg alt={NAMES.APP} aria-label={NAMES.APP} src={logoImgSrc} />
                )}
              </Grid>

              {!hideSearchSchools && (
                <Grid display="flex" item sx={{ justifyContent: 'center', minWidth: 'calc(100vw - 80%)' }}>
                  {isLoadingSecondarySchool && <Loading />}

                  {schoolName && userCanSearchSchools ? (
                    <HighschoolSelectButton
                      disableFocusRipple
                      endIcon={<Icon icon="general.chevron.down" />}
                      id={TAG_IDS.APP_LAYOUT.SEARCH_FOR_SCHOOLS_BUTTON}
                      onClick={(): void => setShowSchoolSearch(true)}
                    >
                      <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} variant="buttonLarge">
                        {schoolName}
                      </Typography>
                    </HighschoolSelectButton>
                  ) : (
                    <HighschoolSelectedText $enableHover={userCanSearchSchools} variant="buttonLarge">
                      {schoolName}
                    </HighschoolSelectedText>
                  )}
                </Grid>
              )}

              {!hideUserMenu && <Grid item>{activeUser && <UserMenu schoolId={schoolId} />}</Grid>}
            </BarContentGridContainer>
          </BarContentContainer>
          <SchoolSelectionDialog handleClose={handleDialogClose} open={showSchoolSearch} />
        </BarRoot>

        <PageMain $loggedIn={isUserLoggedIn} $shouldRemoveScrollbar={Boolean(hasSecondaryHeader)}>
          <PageContentContainer maxWidth={false} style={{ paddingLeft: 0 }} sx={{ ...contentContainerProps }}>
            {loading && <Loading />}

            {!loading && (
              <>
                {is404 && NotFound404}

                {!is404 && (
                  <>
                    {
                      // We must check if activeUser is not null, because HomeDialogHandler
                      // calls useFeatureFlags, which needs a valid token.
                      // Alternatively we could open a featureFlags endpoint that doesn't need a valid
                      // auth token.
                      activeUser &&
                        (schoolId === EDUCATION_PARTNER_HS_ID && activeUser.type !== USER_TYPES.LDAP ? (
                          <EducationPartnerDialogHandler />
                        ) : (
                          <HomeDialogHandler />
                        ))
                    }
                    {children}
                  </>
                )}
              </>
            )}
          </PageContentContainer>
          {/* The check for active user was added because we need the footer to be on the children so that we can
          extend the new nav bar menu for the whole page. So while not logged in, this will be the footer displayed */}
          {showFooter && !activeUser && (
            <Grid container style={{ alignContent: 'space-evenly', height: '52px', marginTop: 'auto' }}>
              <Footer fontWeight="light" variant="caption">
                ©Copyright {moment().format('YYYY')}. Encoura, LLC. All rights reserved.
              </Footer>
            </Grid>
          )}
        </PageMain>
      </BarWrapper>
    </>
  );
};

export default AppLayout;
