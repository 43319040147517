import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { startCase } from 'lodash';
import * as React from 'react';

import NAMES from '~/constants/names';
import TAG_IDS from '~/constants/tagIds';
import localCache from '~/helpers/localCache';
import useLandingPageRedirect from '~/hooks/useLandingPageRedirect';
import { UserRoles } from '~/types/users';

import { StyledLink, StyledLinkGrid, StyledLocaleTypography, StyledRoleTypography } from './styles';

export interface ISchool {
  hs_id: string;
  name: string;
  city?: string;
  state?: string;
  address?: string;
  role?: UserRoles;
}

interface ILinkProps {
  closeDialog?: () => void;
  linkProps?: React.HTMLAttributes<HTMLLIElement>;
  maxItemSize?: number;
  menuWidth?: number;
  school: Partial<ISchool>;
  shouldWrapLabel?: boolean | 0;
  showCityAndState?: boolean;
}

const SchoolLink: React.FC<ILinkProps> = props => {
  const { linkProps, school, showCityAndState = false, closeDialog, shouldWrapLabel = false } = props;

  const handleOnClick = (): void => {
    localCache.set(NAMES.SCHOOL_ID, school.hs_id);
    if (closeDialog) closeDialog();
  };

  const baseLandingPage = useLandingPageRedirect(school.hs_id as string);

  return (
    <li {...linkProps} style={{ padding: 0 }}>
      <Grid item xs={12}>
        <StyledLink
          href={baseLandingPage}
          id={TAG_IDS.SCHOOL_SEARCH.SEARCH_SCHOOL_LINK(school.hs_id as string)}
          onClick={handleOnClick}
          underline="none"
          variant="body1"
        >
          <StyledLinkGrid item xs={12}>
            <Grid container justifyContent="space-between" rowGap={1}>
              <Grid item xs={shouldWrapLabel ? 12 : 8}>
                <Typography variant="body1">{school.name}</Typography>
              </Grid>
              {!showCityAndState && school.role && (
                <Grid container item justifyContent={shouldWrapLabel ? 'start' : 'end'} xs={shouldWrapLabel ? 12 : 4}>
                  <StyledRoleTypography sx={{ color: theme => theme.palette.text.overLight.medium }} variant="body1">
                    {startCase(school.role)}
                  </StyledRoleTypography>
                </Grid>
              )}
              {showCityAndState && (
                <Grid container item justifyContent={shouldWrapLabel ? 'start' : 'end'} xs={shouldWrapLabel ? 12 : 4}>
                  <StyledLocaleTypography variant="body1">{school.city && school.state && `${school.city}, ${school.state}`}</StyledLocaleTypography>
                </Grid>
              )}
            </Grid>
          </StyledLinkGrid>
        </StyledLink>
      </Grid>
    </li>
  );
};

export default SchoolLink;
