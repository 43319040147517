import { styled } from '~/helpers/styled';
import palette from '~/styles/ThemeEncourageE4E/palette';

import Alert from '../Alert';

// eslint-disable-next-line import/prefer-default-export
export const AlertStyled = styled(Alert)(() => ({
  '&.MuiAlert-outlinedError': {
    borderColor: palette.utility.error[700],
    borderLeft: `4px solid ${palette.utility.error[700]}`,
  },
  '&.MuiAlert-outlinedInfo': {
    borderColor: `${palette.branding.sapphire[500]}`,
    borderLeft: `4px solid ${palette.branding.sapphire[500]}`,
    svg: {
      fill: `${palette.branding.sapphire[500]}`,
    },
  },
  '&.MuiAlert-outlinedSuccess': {
    borderColor: `${palette.utility.success[500]}`,
    borderLeft: `4px solid ${palette.utility.success[500]}`,
    svg: {
      fill: `${palette.utility.success[500]}`,
    },
  },
  '&.MuiAlert-outlinedWarning': {
    borderColor: `${palette.utility.warning[600]}`,
    borderLeft: `4px solid ${palette.utility.warning[600]}`,
  },
  '&.MuiAlert-root': {
    borderRadius: '2px 4px 4px 2px',
    boxShadow:
      '0px 16px 32px rgba(79, 94, 113, 0.08), 0px 8px 16px rgba(79, 94, 113, 0.09), 0px 4px 8px rgba(79, 94, 113, 0.1)' +
      ', 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12)',
    color: palette.text.overLight.high,
    height: '66px',
    maxWidth: '600px',
    minWidth: '250px',
    padding: '21px 8px 21px 16px',
  },
  '&.MuiAlert-standard': {
    '.MuiAlert-icon': {
      display: 'flex',
      svg: {
        fill: `rgba(0, 0, 0, 0.6)`,
      },
    },
    background: 'white',
    borderColor: `${palette.branding.mist[500]}`,
    borderLeft: `4px solid ${palette.branding.mist[500]}`,
  },
}));
