import Grid from '@mui/material/Grid';

import getIcon from '~/helpers/getIcon';
import { styled } from '~/helpers/styled';

import Autocomplete from '../Autocomplete';

const ChevronDown = getIcon('general.chevron.down');
const Magnify = getIcon('actions.search');

export const AutocompleteStyled = styled(Autocomplete)(context => ({
  '& .MuiOutlinedInput-root': {
    padding: 12,
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    borderRadius: 0,
    marginTop: context.size === 'large' ? '0' : '-3.5px',
    padding: 0,
  },
  '& .MuiOutlinedInput-root .MuiInputAdornment-root': {
    button: {
      background: 'unset',
    },
    marginLeft: 0,
    marginRight: 0,
    marginTop: context.size === 'large' ? '0' : '-3.5px',
  },
  '& .MuiOutlinedInput-root .MuiInputAdornment-root .MuiIconButton-root.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: context.theme.palette.text.overLight.medium,
  },
  '&.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
    padding: 12,
  },
  '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
    padding: 12,
  },
  '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
    padding: 12,
  },
  '&.MuiAutocomplete-root .MuiInputBase-root.Mui-disabled:hover>fieldset': {
    borderColor: context.theme.palette.branding.surface[700],
  },
  '&.MuiAutocomplete-root>.MuiTextField-root>.MuiAutocomplete-inputRoot.MuiOutlinedInput-root.Mui-disabled>input.Mui-disabled':
    {
      padding: 0,
    },
  '&.MuiAutocomplete-root>.MuiTextField-root>.MuiInputBase-root.MuiAutocomplete-inputRoot>input.MuiInputBase-input': {
    padding: 0,
  },
  '.MuiFormControl-root': {
    height: '100%',
  },
  label: {
    '&:not(.MuiInputLabel-shrink)': {
      marginTop: context.size === 'large' ? '0 !important' : '-3.5px',
    },
  },
  width: context.fullWidth ? '100%' : '320px',
})) as typeof Autocomplete;

export const SearchStyled = styled(Magnify)<{ $isEditing: boolean }>(context => {
  const isEditingOverride = context.$isEditing
    ? {
        marginRight: 8,
      }
    : {};

  return {
    '&>svg.MuiSvgIcon-root': {
      height: 24,
      width: 24,
    },
    ...isEditingOverride,
  };
});

export const ChevronDownStyled = styled(ChevronDown)(() => ({
  '&>svg.MuiSvgIcon-root': {
    height: 24,
    width: 24,
  },
}));

export const OptionDisabled = styled('li')(({ theme }) => ({
  color: theme.palette.text.overLight.medium,
}));

export const SecondColumnOption = styled(Grid)(({ theme }) => ({
  color: theme.palette.text.overLight.medium,
}));
