import dynamic from 'next/dynamic';
import React from 'react';

const DebugMenuProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isNonProdEnv = process.env.NODE_ENV !== 'production';

  const DebugMenuComponent = React.useMemo(() => (isNonProdEnv ? dynamic(() => import('~/components/DebugMenu'), { ssr: false }) : null), [isNonProdEnv]);

  return (
    <>
      {children}
      {DebugMenuComponent && <DebugMenuComponent />}
    </>
  );
};

export default DebugMenuProvider;
