import { Loading } from '@actinc/dls/components/Loading';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';
import { FC, ReactElement, useEffect, useState } from 'react';

import Link from '~/components/Link';
import { ROUTES, ROUTES_REGEX } from '~/constants/routes';
import TAG_IDS from '~/constants/tagIds';
import { asError } from '~/helpers/errors';
import { isPathEquivalent, isRegexPathEquivalent } from '~/helpers/path';
import useLogger from '~/hooks/useLogger';

import { InnerContainer, OuterContainer, RootTypography, RootTypographyTitle } from './styles';

interface IProps {
  disableRedirect?: boolean;
}

const NotFound404: FC<IProps> = (props: IProps): ReactElement<unknown> => {
  const { disableRedirect = false } = props;
  const logger = useLogger('RENDER_404');
  const [loading, setLoading] = useState(true);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [logged404, setLogged404] = useState(false);

  const router = useRouter();

  logger.debug('NotFound404', { routerAsPath: router.asPath, routes: ROUTES_REGEX });

  useEffect((): void => {
    if (!disableRedirect) {
      if (isPathEquivalent(ROUTES.INDEX, router.asPath)) {
        setShouldNavigate(true);
      } else {
        setShouldNavigate(
          Object.keys(ROUTES_REGEX).some(routeKey => isRegexPathEquivalent(ROUTES_REGEX[routeKey as keyof typeof ROUTES_REGEX], router.asPath)),
        );
      }
      setLoading(false);
    }
  }, [router, disableRedirect]);

  useEffect((): void => {
    if (shouldNavigate) {
      // eslint-disable-next-line promise/prefer-await-to-then
      router.replace(router.asPath).catch((unkErr: unknown) => {
        const error = asError(unkErr);
        logger.error('Error while replacing 404 route', error);
      });
    } else if (!loading && !logged404) {
      logger.error(`${router.asPath} requested: 404`);
      setLogged404(true);
    }
  }, [logger, router, shouldNavigate, loading, logged404]);

  return (
    <OuterContainer>
      <InnerContainer>
        {(loading || shouldNavigate) && <Loading />}

        {!loading && !shouldNavigate && (
          <>
            <RootTypographyTitle variant="h4">Page not found.</RootTypographyTitle>

            <RootTypography variant="body1">The page you were looking for does not exist, or it may be private.</RootTypography>

            <RootTypography>
              <Link href={ROUTES.INDEX} id={TAG_IDS.NOT_FOUND_PAGE.NOT_FOUND_HOME_LINK} underline="none">
                <Button id={TAG_IDS.NOT_FOUND_PAGE.NOT_FOUND_HOME_LINK_BUTTON} variant="outlined">
                  Return Home
                </Button>
              </Link>
            </RootTypography>
          </>
        )}
      </InnerContainer>
    </OuterContainer>
  );
};

export default NotFound404;
