import Button from '@mui/material/Button';
import Grid, { GridProps } from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import { FC, ReactElement } from 'react';

import TAG_IDS from '~/constants/tagIds';
import { asError } from '~/helpers/errors';
import useLogger from '~/hooks/useLogger';
import useSchoolInfo from '~/hooks/useSchoolInfo';

import { IconBackground, IconContainer, IconStyled, ReportCard, ReportNameGrid, ReportNameTypography } from './styles';

export interface IReportElement extends GridProps {
  isLargeDialog?: boolean;
  report: Record<string, string>;
  reportNameSize?: number;
  close: () => void;
}

const ReportRedirect: FC<IReportElement> = (iReportElement: IReportElement): ReactElement => {
  const { close, isLargeDialog = false, report, reportNameSize = 1, ...otherProps } = iReportElement;
  const logger = useLogger('REPORT_REDIRECT');
  const router = useRouter();

  // Specific breakpoints for different dialog sizes
  const buttonLabel = useMediaQuery(`(min-width: ${isLargeDialog ? 874 : 487}px)`);

  const schoolInfo = useSchoolInfo()?.data?.school;
  const hsId = schoolInfo?.hs_id ?? '';
  let reportRoute = '';

  if (report.default === 'Superintendent Report' || report.default === 'National Report') {
    reportRoute = report.route;
  } else {
    reportRoute = `${report.route}/${hsId}`;
  }

  const handleRedirect = (): void => {
    if (router.asPath === reportRoute) {
      close();
    } else {
      // eslint-disable-next-line promise/prefer-await-to-then, promise/prefer-await-to-callbacks
      router.push(reportRoute).catch((err: unknown) => {
        logger.error(asError(err));
      });
    }
  };

  return (
    <Grid {...otherProps} container item>
      <ReportCard
        alignItems="center"
        container
        direction="column"
        id={TAG_IDS.DIALOGS.EDUCATION_PARTNER.REPORT_TITLE_BUTTON_PREFIX + report.id}
        item
        justifyContent="center"
        onClick={handleRedirect}
      >
        <IconContainer alignItems="center" container item justifyContent="center">
          <IconBackground container item style={{ placeContent: 'center' }}>
            <IconStyled fontSize="large" icon="general.report" />
          </IconBackground>
        </IconContainer>
        <ReportNameGrid item textAlign="center">
          <ReportNameTypography $textLines={reportNameSize ?? 1} alignItems="center" component="span" variant="h6">
            {report.default}
          </ReportNameTypography>
        </ReportNameGrid>
        <Grid item>
          <Button
            color="primary"
            id={TAG_IDS.DIALOGS.EDUCATION_PARTNER.GO_TO_REPORT_BUTTON_PREFIX + report.id}
            onClick={handleRedirect}
            size="medium"
            variant="contained"
          >
            {buttonLabel ? 'Open Report' : 'Open'}
          </Button>
        </Grid>
      </ReportCard>
    </Grid>
  );
};

export default ReportRedirect;
