import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography, { TypographyProps } from '@mui/material/Typography';

import { styled, withNode } from '~/helpers/styled';

export const BarRoot = styled(withNode(AppBar, 'div'))<AppBarProps & { $loggedIn: boolean }>(
  ({ $loggedIn: loggedIn, theme }) => ({
    backgroundColor: loggedIn ? theme.palette.branding.seafoam[300] : theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.branding.surface[300]}`,
    height: theme.customDims.heights.header,
    width: '100%',
  }),
);

export const BarWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  marginLeft: 0,
  paddingLeft: 0,
}));

export const BarContentContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  padding: useMediaQuery(theme.breakpoints.down('sm')) ? '0 24px' : '0',
  width: '100%',
}));

export const BarContentGridContainer = styled(Grid)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: '32px',
  height: '100%',
  justifyContent: 'space-between',
  width: '100%',
}));

export const Footer = styled(withNode(Typography, 'footer'))(({ theme }) => ({
  alignSelf: 'end',
  color: theme.palette.text.overLight.medium,
  display: 'block',
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '20px',
  textAlign: 'center',
  width: '100%',
}));

export const PageMain = styled(withNode(Box, 'main'))<
  BoxProps & { $loggedIn: boolean; $shouldRemoveScrollbar: boolean }
>(({ $loggedIn, $shouldRemoveScrollbar, theme }) => ({
  background: theme.palette.branding.surface[200],
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 2,
  paddingLeft: theme.spacing(0),
  width: '100%',

  ...($loggedIn && {
    marginTop: theme.spacing(2),
    overflowY: 'auto',
  }),
  ...($shouldRemoveScrollbar && {
    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
}));

export const PageContentContainer = styled(Container)(({ theme }) => ({
  flexGrow: 2,
  marginTop: theme.customDims.heights.header,
  paddingRight: 24,
  [theme.breakpoints.down('sm')]: {
    paddingRight: 16,
  },
}));

export const HighschoolSelectButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    borderWidth: 0,
  },
  borderWidth: 0,
  color: theme.palette.branding.sapphire[500],
  padding: useMediaQuery(theme.breakpoints.down('sm')) ? '14px 8px' : 'inherit',
  textTransform: 'none',
}));

export const HighschoolSelectedText = styled(Typography)<TypographyProps & { $enableHover: boolean | undefined }>(
  ({ $enableHover: enableHover, theme }) => ({
    '&:hover': {
      backgroundColor: enableHover ? theme.palette.common.white : 'unset',
      borderWidth: 0,
    },
    borderWidth: 0,
    color: theme.palette.text.overLight.medium,
  }),
);

export const LogoImg = styled('img')(() => ({
  display: 'block',
  height: '40px',
  width: '172px',
}));
