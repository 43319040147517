import { gql, useApolloClient } from '@apollo/client';
import { wrapRestQuery } from '@encoura/apollo-rest-utils';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

import NAMES from '~/constants/names';
import REST from '~/constants/rest';
import localCache from '~/helpers/localCache';
import sentryGraphqlRequestError from '~/helpers/SentryErrors/sentryGraphqlRequestError';
import useLogger from '~/hooks/useLogger';
import { setToken } from '~/hooks/useToken';

import { SpinnerContainer } from './styles';

interface IProps {
  setIsUserImpersonating: (value: boolean) => void;
  userUid: string;
}

export const STOP_IMPERSONATION_USER = gql`
  query EndImpersonationQuery($uid: ID!) {
    newUserToken(uid: $uid) {
      sessionToken
    }
  }
`;

const StopImpersonate: React.FC<IProps> = ({ setIsUserImpersonating, userUid }): React.ReactElement<unknown> => {
  const logger = useLogger();
  const apolloClient = useApolloClient();

  const { data: endImpersonationData, loading } = wrapRestQuery<'newUserToken'>()(STOP_IMPERSONATION_USER, {
    endpoint: REST.DELETE.USERS_BY_UID_IMPERSONATE,
    skip: !userUid,
    variables: {
      uid: userUid,
    },
  });

  React.useEffect(() => {
    try {
      if (!loading && endImpersonationData) {
        // eslint-disable-next-line promise/prefer-await-to-then, promise/prefer-await-to-callbacks
        logger.log('Stop Impersonating old user with uid', userUid);
        setToken(endImpersonationData.newUserToken.sessionToken);
        setToken(undefined, false);
        localCache.set(NAMES.SCHOOL_ID, undefined);
        setIsUserImpersonating(false);
        apolloClient
          .resetStore()
          // eslint-disable-next-line promise/prefer-await-to-then, promise/prefer-await-to-callbacks
          .catch((err: unknown) => {
            logger.error(err as Error);
          });
      }
    } catch (err: unknown) {
      sentryGraphqlRequestError({ error: err, level: 'error', variables: {} });
      logger.error(err as Error);
    }
  }, [apolloClient, endImpersonationData, loading, logger, setIsUserImpersonating, userUid]);

  return (
    <SpinnerContainer>
      <CircularProgress />;
    </SpinnerContainer>
  );
};

export default StopImpersonate;
