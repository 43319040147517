import { createTheme as muiCreateTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';
import deepMerge from 'deepmerge';

import components from './components';
import E4ECustomDimensions from './E4ECustomDimensions';
import palette, { CustomPaletteOptions } from './palette';
import props from './props';
import spacing, { SPACING_PX } from './spacing';
import typography from './typography';

import type { Shadows } from '@mui/material';

export type CustomTheme<T extends object> = Theme & T;

export interface ICustomDims {
  customDims: {
    heights: Record<string, number>;
    widths: Record<string, number>;
  };
}

export type CustomThemeOptions<T extends object = Record<string, never>> = ThemeOptions & T;

export const createTheme = <T extends object>(options: CustomThemeOptions<T>, ...args: object[]): CustomTheme<T> =>
  muiCreateTheme(options, ...args) as CustomTheme<T>;

const baseTheme = muiCreateTheme({});

type ThemeCustomizations = ICustomDims & {
  palette: CustomPaletteOptions;
  spacingPx: number;
};

export const THEME_ENCOURAGE_E4E_OPTIONS: CustomThemeOptions<ThemeCustomizations> = deepMerge(
  baseTheme,
  {
    breakpoints: createBreakpoints({
      values: {
        lg: 1440,
        md: 1280,
        sm: 820,
        xl: 1920,
        xs: 0,
      },
    }),
    components,
    customDims: {
      ...E4ECustomDimensions,
    },
    palette,
    props,
    shadows: [
      ...baseTheme.shadows.map((shadow, index) => {
        const SHADOWS_MAPPING: { [key: number]: string } = {
          0: 'none',
          2: '0px 4px 8px rgba(79, 94, 113, 0.1), 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12);',
          23:
            '0px 0px 2px 0px rgba(79, 94, 113, 0.12),' +
            '0px 2px 4px 0px rgba(79, 94, 113, 0.11),' +
            '0px 4px 8px 0px rgba(79, 94, 113, 0.1);',
          24:
            '0px 32px 64px rgba(79, 94, 113, 0.07), 0px 16px 32px rgba(79, 94, 113, 0.08),' +
            '0px 8px 16px rgba(79, 94, 113, 0.09), 0px 4px 8px rgba(79, 94, 113, 0.1),' +
            '0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12);',
          6:
            '0px 16px 32px rgba(79, 94, 113, 0.09), 0px 4px 8px rgba(79, 94, 113, 0.1),' +
            '0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12);',
          8:
            '0px 16px 32px rgba(79, 94, 113, 0.08), 0px 8px 16px rgba(79, 94, 113, 0.09),' +
            '0px 4px 8px rgba(79, 94, 113, 0.1), 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12);',
        };

        return SHADOWS_MAPPING[index] || shadow;
      }),
    ] as Shadows,
    spacing,
    spacingPx: SPACING_PX,
    typography,
  },
  // Replace arrays instead of merging them together
  { arrayMerge: (_: unknown[], source: unknown[]) => source },
);

export const THEME_ENCOURAGE_E4E = createTheme(THEME_ENCOURAGE_E4E_OPTIONS);

export default THEME_ENCOURAGE_E4E;
