import Avatar, { AvatarProps } from '@mui/material/Avatar';

import Icon, { IconProps } from '~/components/Icon';
import { createThemeStyled } from '~/helpers/styled';
import THEME_ENCOURAGE_E4E from '~/styles/ThemeEncourageE4E';

const styled = createThemeStyled<typeof THEME_ENCOURAGE_E4E>();

// eslint-disable-next-line import/prefer-default-export
export const StyledAvatar = styled(Avatar)<AvatarProps & { $colorVariant: string; $disabled?: boolean; $size: string }>(
  context => ({
    ...(context.$colorVariant === 'navy' && {
      '*': {
        color: context.theme.palette.branding.navy[500],
      },
      backgroundColor: context.theme.palette.branding.navy[50],
    }),
    ...(context.$colorVariant === 'neon' && {
      '*': {
        color: context.theme.palette.branding.sapphire[600],
      },
      backgroundColor: context.theme.palette.branding.neon[200],
    }),
    ...(context.$colorVariant === 'seafoam' && {
      '*': {
        color: context.theme.palette.branding.navy[500],
      },
      backgroundColor: context.theme.palette.branding.seafoam[200],
    }),
    ...(context.$colorVariant === 'white' && {
      '*': {
        color: context.theme.palette.branding.navy[500],
      },
      backgroundColor: context.theme.palette.common.white,
    }),
    ...(context.$disabled && {
      '*': {
        color: context.theme.palette.branding.surface[700],
      },
      backgroundColor: context.theme.palette.branding.surface[100],
    }),
    ...(context.$size === 'small' && {
      height: '32px',
      padding: 1,
      width: '32px',
    }),
    ...(context.$size === 'medium' && {
      height: '40px',
      padding: 1,
      width: '40px',
    }),
    ...(context.$size === 'large' && {
      height: '48px',
      padding: 2,
      width: '48px',
    }),
    ...(context.$size === 'xlarge' && {
      height: '64px',
      padding: 4,
      width: '64px',
    }),
  }),
);

export const StyledIcon = styled(Icon)<IconProps & { $size: string }>(context => ({
  ...(context.$size === 'small' && {
    height: '24px',
    width: '24px',
  }),
  ...(context.$size === 'medium' && {
    height: '24px',
    width: '24px',
  }),
  ...(context.$size === 'large' && {
    height: '32px',
    width: '32px',
  }),
  ...(context.$size === 'xlarge' && {
    height: '32px',
    width: '32px',
  }),
}));
