import { SessionTimer } from '@actinc/dls/components/SessionTimer';
import { gql, useApolloClient } from '@apollo/client';
import { wrapRestClientQuery } from '@encoura/apollo-rest-utils';
import { FC, ReactElement, useCallback, useState } from 'react';

import NAMES from '~/constants/names';
import { REST } from '~/constants/rest';
import { asError } from '~/helpers/errors';
import localCache from '~/helpers/localCache';
import sentryGraphqlRequestError from '~/helpers/SentryErrors/sentryGraphqlRequestError';
import useActiveUser from '~/hooks/useActiveUser';
import useLogger from '~/hooks/useLogger';
import { setToken } from '~/hooks/useToken';
import { IImpersonating } from '~/types';

interface IProps {
  onExpire?: () => void;
}

const RefreshToken: FC<IProps> = ({ onExpire }: IProps): ReactElement<unknown> | null => {
  const client = useApolloClient();
  const logger = useLogger('REFRESH_TOKEN');
  const me = useActiveUser();

  const impersonating = !!localCache.get(NAMES.IMPERSONATING);

  const [expirationMS, setExpirationMS] = useState(0);
  const [expireTimeoutHandle, setExpireTimeoutHandle] = useState<NodeJS.Timeout | undefined>(undefined);

  const handleRefreshToken = useCallback(async (): Promise<void> => {
    try {
      const wrappedRestQuery = wrapRestClientQuery<'refreshToken'>();

      const { data } = await wrappedRestQuery({
        client,
        endpoint: REST.GET.REFRESH_TOKEN,
        fetchPolicy: 'network-only',
        query: gql`
          query RefreshTokenQuery {
            refreshToken {
              sessionToken
            }
          }
        `,
        variables: {},
      });

      const token = data?.refreshToken?.sessionToken;

      if (token) {
        logger.debug('Token refreshed', { impersonating, token });
        setToken(token, !impersonating);
      }
    } catch (unkErr: unknown) {
      const error = asError(unkErr);
      sentryGraphqlRequestError({ error, level: 'error', variables: {} });
      logger.error('Error while refreshing token', error);
    }
  }, [logger, impersonating, client]);

  if (!me) return null;

  if (me.exp && me.iat) {
    const expiresAt = new Date(me.exp * 1000);
    const tokenMaxAgeMs = (me.exp - me.iat) * 1000;
    const promptWithMsRemaining = 5 * 60 * 1000; // 5 minutes

    if (tokenMaxAgeMs !== expirationMS) {
      logger.debug('Resetting expiration timeout', { tokenMaxAgeMs });
      if (expireTimeoutHandle) {
        clearTimeout(expireTimeoutHandle);
      }

      if (onExpire) {
        setExpireTimeoutHandle(
          setTimeout(() => {
            logger.debug('onExpire');
            onExpire();
          }, tokenMaxAgeMs),
        );
      }
      setExpirationMS(tokenMaxAgeMs);
    }

    logger.debug('RefreshToken render', {
      expiresAt,
      impersonating: !!localCache.get<IImpersonating>(NAMES.IMPERSONATING),
      promptWithMsRemaining,
      token: localCache.get(NAMES.TOKEN),
      tokenMaxAgeMs,
    });

    return (
      <SessionTimer
        expiresAt={expiresAt}
        onExpire={(): void => {
          logger.debug('Token expired');
          if (onExpire) {
            onExpire();
          }
        }}
        onKeepAlive={(): void => {
          void handleRefreshToken(); // eslint-disable-line no-void
        }}
        promptWithMsRemaining={promptWithMsRemaining}
        tokenMaxAgeMs={tokenMaxAgeMs}
      />
    );
  }
  return null;
};

export default RefreshToken;
