export const FEATURE_FLAGS_PREFIX = 'encourage.featureflag.';

// BEFORE CREATING A NEW FEATURE FLAG PAY ATTENTION ON THE PATTERN WE USE:
// There's a route on encourage-service the will create the feature flag for you (/feature-flags)
// You just need to provide the FeatureFlag name and the route will create a key like this:
// encourage.featureflag.enable.FEATURE_NAME
// IMPORTANT: True means that the feature is on and False that we should hide it from users

export const HOME_MODAL = 'FEATURE_HOME_MODAL';
// We are hiding this report until we figure out with ESE if we need it
export const NEW_STUDENT_DETAILS_PAGE = 'NEW_STUDENT_DETAILS_PAGE';
