import first from 'lodash/first';

import { EDUCATION_PARTNER_HS_ID, HIGH_SCHOOL_REPORTS, HIGH_SCHOOL_REPORTS_CODES } from '~/constants/reports';
import { HS_REGEX, ROUTES, ROUTES_REGEX } from '~/constants/routes';
import USER_TYPES from '~/constants/userTypes';
import { WalkMeSetUserData } from '~/helpers/walkme/WalkMeInsights';

import useActiveUser from '../useActiveUser';
import useDetailedPermissions from '../useDetailedPermissions';
import useQueryParam from '../useQueryParam';

const useLandingPageRedirect = (hsId: string): string => {
  const activeUser = useActiveUser();
  const getDetailedPermissions = useDetailedPermissions();

  const returnUrl = useQueryParam('returnUrl');

  WalkMeSetUserData({ role: undefined, uid: activeUser?.uid as string });

  if (returnUrl) {
    const returnUrlWithShoolIdCompare = `${returnUrl as string}/${HS_REGEX}`.replace(/\//g, '\\/');

    let routeWithShoolIdRegex = false;
    Object.entries(ROUTES_REGEX).forEach(route => {
      if (route[1] === returnUrlWithShoolIdCompare) routeWithShoolIdRegex = true;
    });

    if (hsId === EDUCATION_PARTNER_HS_ID && activeUser?.type !== USER_TYPES.LDAP) {
      WalkMeSetUserData({ role: 'partner', uid: activeUser?.uid as string });
    }

    return routeWithShoolIdRegex ? `${returnUrl as string}/${hsId}` : (returnUrl as string);
  }

  const detailedPermissions = getDetailedPermissions.data?.detailedPermissions;

  const userHsIds = detailedPermissions?.map(permission => permission.organization?.externalId);

  if (hsId === EDUCATION_PARTNER_HS_ID && detailedPermissions && detailedPermissions.length > 0) {
    const partnerOrgUserPermissions = detailedPermissions.filter(
      schoolPermission => schoolPermission.organization?.externalId === EDUCATION_PARTNER_HS_ID,
    );
    const userPermissions = partnerOrgUserPermissions.map(({ permissions }) => permissions);
    const partnerSpecificReport: string[] = [];

    userPermissions.forEach(
      permissions =>
        permissions &&
        Object.entries(permissions).forEach(([key, value]) => {
          if (value && HIGH_SCHOOL_REPORTS_CODES.includes(key)) {
            partnerSpecificReport.push(key);
          }
        }),
    );

    WalkMeSetUserData({ role: 'partner', uid: activeUser?.uid as string });

    let firstReportPage = ROUTES.REPORTS_NATIONAL_REPORT;
    if (partnerSpecificReport.length > 0) {
      const specificReport = first(partnerSpecificReport.sort());
      if (specificReport) {
        firstReportPage = `${HIGH_SCHOOL_REPORTS[specificReport].route}/${hsId}`;
      }
    }

    return `${firstReportPage}`;
  }

  if (activeUser?.type === USER_TYPES.LDAP) {
    WalkMeSetUserData({ role: 'ldap_user', uid: activeUser?.uid });
    return `${ROUTES.ADMINISTRATION}/${hsId}`;
  }

  if (userHsIds && userHsIds.length < 1) return `${ROUTES.MY_STUDENTS}/${hsId}`;

  if (userHsIds && userHsIds?.length >= 1) {
    const hsIdIndex = userHsIds.indexOf(hsId, 0);
    const permissions = detailedPermissions && detailedPermissions[hsIdIndex].permissions;
    if (permissions?.highSchoolAdmin) return `${ROUTES.ADMINISTRATION}/${hsId}`;
  }
  return `${ROUTES.MY_STUDENTS}/${hsId}`;
};

export default useLandingPageRedirect;
