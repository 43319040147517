import { AlertProps } from '@mui/material/Alert';
import { FC, ReactElement } from 'react';

import { SeverityType } from '~/components/Alert';

import { AlertStyled } from './styles';

type ISnackbarAlertProps = AlertProps & {
  severity: SeverityType;
};

const SnackbarAlert: FC<ISnackbarAlertProps> = ({ children, onClose, style, severity }: ISnackbarAlertProps): ReactElement<unknown> => (
  <AlertStyled message={children} onClose={onClose} severity={severity} style={style} variant="outlined" />
);

export default SnackbarAlert;
